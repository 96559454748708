// ==============================
// Custom style
// 自定义样式
// ==============================

.resumecontainer {
    padding: 0 0 .5rem; 
    display: block;
    max-width: 950px;
    // max-width: 80ch;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    margin: 0 auto;
    a:not(.Navigation__link):not(.Title__link) {
      outline: 0;
      color: inherit;
      border-bottom: 0;
      text-decoration: none;
      background-image: linear-gradient(to right,#f2cfe3,#c4e0f3);
      background-repeat: no-repeat;
      background-size: 100% .2em;
      background-position: 0 88%;
      transition: 250ms ease-in
  }

  a:not(.Title__link):active,a:not(.Title__link):focus,a:not(.Title__link):hover {
      background-size: 100% 88%
  }

  a[data-visited]:not([href^='#'])::after {
      content: ' ✓';
      opacity: .5;
      font-size: 80%
  }

  a:visited {
      color: inherit
  }
}


@media (min-width: 768px){
.resumecontainer {
    padding-left: 2em;
    padding-right: 2em;
}
}
.Resume__photo {
    float: left;
    shape-outside: circle(50%);
    margin: 0.5em 1em 0.5em 0;
    border-radius: 50%;
    max-width: 8em;
    display: none;
  }
  
  .Resume__links {
    text-align: center;
  }
  
  .Resume__links--print {
    display: none;
  }
  
  .Resume__intro {
    margin-bottom: 2em;
  }
  
  .Resume__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
  }
  
  .Resume__section > h2 {
    font-style: italic;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .Resume__section > div {
    padding-top: 0.9em;
  }
  
  .Resume__section > div > :last-child {
    margin-bottom: 0;
  }
  
  .Resume__section > div > :first-child {
    margin-top: 0;
  }
  
  .Resume__section h3 {
    font-size: 90%;
  }
  
  .Resume__section h3 span {
    margin-left: auto;
    opacity: 0.8;
    font-weight: normal;
    display: block;
  }
  
  .Resume__section li {
    margin: 0.5em 0;
  }
  
  @media print {
    .Resume {
      font-size: 75%;
    }
  
    .no-print,
    .Resume__links,
    .Resume__photo {
      display: none;
    }
  
    .Resume__links--print {
      display: block;
    }
  }
  
  @media (min-width: 768px) {
    .Resume__photo {
      display: block;
    }
  
    .Resume__section {
      margin-bottom: 3em;
      flex-direction: row;
    }
  
    .Resume__section > h2 {
      margin-top: revert;
      padding-right: 1.5em;
      font-size: 100%;
      text-align: right;
      border-right: 1px solid #dd7eb4;
      margin-right: 1.5em;
      flex: 0 0 8em;
    }
  
    .Resume__section h3 {
      display: flex;
    }
  
    .Resume__section h3 span {
      font-size: 80%;
      padding-left: 1em;
      display: inline-block;
      text-align: right;
    }
  }
  
// 技能点
.resumemain {
  // clear: both;
  // float: left;
  width: 100%;
  // overflow: hidden;
  section, summary {
    display: block;
}
.panels {
  width: 100%;
  min-height: 500px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  position: relative;
}


}

.about {

  padding: 0;
  border-top: none;
  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  *, *:before, *:after, *:hover, *:before:hover, *:after:hover, *:visited, *:before:visited, *:after:visited, *:active, *:before:active, *:after:active {
    outline: none !important;
  }

}

.about section {
  padding: 24px;
}

.about .nav.categories {
  border-bottom: 1px solid #eaeaea;
}

.about .nav.categories li a {
  padding: 24px 16px;
}

@media screen and (max-width: 600px) {
  .about .nav.categories {
      padding: 0;
      text-align: center;
  }

  .about .nav.categories .sublist li a {
      font-size: 12px;
      font-size: 0.75rem;
      padding: 24px 10px;
  }
}

.about .hero .nav.slideshow {
  position: absolute;
  padding-right: 48px;
  bottom: 0;
  left: 0;
  text-align: right;
}

.about .hero .nav.slideshow .list li a, .about .hero .nav.slideshow .list .sublist li a {
  padding: 24px 16px;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .about .hero .nav.slideshow {
      padding: 0;
  }

  .about .hero .nav.slideshow .list {
      text-align: center;
  }

  .about .hero .nav.slideshow .list .sublist {
      display: block;
      margin: 0 auto;
  }
}

@media screen and (max-width: 420px) {
  .about .hero .nav.slideshow {
      display: none;
  }
}

.about .hero {
  color: #fff;
  padding: 0;
  position: relative;
  background: #000;
}

.about .hero .background {
  position: relative;
  width: 100%;
  height: 0;
  padding: 56.25% 0 0 0;
}

.about .hero .headshot {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.about .hero .motto {
  position: absolute;
  max-width: 380px;
  top: 240px;
  left: 48px;
}

.about .hero .motto .text {
  font-size: 32px;
  font-size: 2rem;
  line-height: 160%;
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 1100px) {
  .about .hero .motto span {
      display: inline-block;
  }
}

@media screen and (max-width: 900px) {
  .about .hero .motto {
      top: 200px;
  }

  .about .hero .motto .text {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 140%;
  }
}

@media screen and (max-width: 600px) {
  .about .hero .motto {
      top: auto;
      left: 50%;
      padding: 0 24px;
      text-align: center;
      bottom: 60px;
      max-width: 360px;
      margin: 0 0 0 -180px;
  }

  .about .hero .motto span {
      display: inline;
  }
}

@media screen and (max-width: 420px) {
  .about .hero .motto {
      bottom: 22px;
      max-width: 320px;
      width: 100%;
      margin: 0 0 0 -160px;
  }

  .about .hero .motto .text {
      font-size: 18px;
      font-size: 1.125rem;
  }
}

.about .hero .motto .button {
  margin: 24px 0 0 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  padding: 16px 48px;
  border: 1px solid #fff;
  position: absolute;
}

.about .hero .images, .about .hero .image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.about .hero .image {
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  -webkit-transition: opacity 2s;
  -moz-transition: opacity 2s;
  -o-transition: opacity 2s;
  -ms-transition: opacity 2s;
  transition: opacity 2s;
}

.about .hero .image.active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}

.about .hero .image:before, .about .hero .image:after {
  content: '';
  position: absolute;
  height: 80px;
  width: 100%;
  left: 0;
}

.about .hero .image:before {
  top: 0;
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0));
}

.about .hero .image:after {
  bottom: 0;
  background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -moz-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -o-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -ms-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));
}

.about .title {
  margin-bottom: 24px;
}

.about .biography, .about .technologies, .about .books, .about .awards, .about .charities, .about .publications {
  padding: 48px;
  position: relative;
  // border-bottom: 1px solid #eaeaea;
}

@media screen and (max-width: 600px) {
  .about .biography, .about .technologies, .about .books, .about .awards, .about .charities, .about .publications {
      padding: 48px 24px;
  }
}

.about .biography .title, .about .technologies .title, .about .books .title, .about .awards .title, .about .charities .title, .about .publications .title {
  margin: 0 0 24px;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .about .biography .title, .about .technologies .title, .about .books .title, .about .awards .title, .about .charities .title, .about .publications .title {
      font-size: 18px;
      font-size: 1.125rem;
      margin: 0 0 12px;
  }
}

.about .biography .description, .about .technologies .description, .about .books .description, .about .awards .description, .about .charities .description, .about .publications .description {
  max-width: 700px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .about .biography .description, .about .technologies .description, .about .books .description, .about .awards .description, .about .charities .description, .about .publications .description {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 220%;
      margin-bottom: 0;
  }
}

.about .biography .list, .about .technologies .list, .about .books .list, .about .awards .list, .about .charities .list, .about .publications .list {
  width: 100%;
  margin: 24px 0 0 0;
}

.about .technologies, .about .books, .about .awards, .about .publications, .about .charities {
  padding: 48px 0 0;
}

.about .technologies .title, .about .books .title, .about .awards .title, .about .publications .title, .about .charities .title {
  padding: 0 0px 4.8px;
  margin: 0 0 4.8px;
}

@media screen and (max-width: 600px) {
  .about .technologies .title, .about .books .title, .about .awards .title, .about .publications .title, .about .charities .title {
      padding: 0 24px 4.8px;
  }
}

.about .technologies .description, .about .books .description, .about .awards .description, .about .publications .description, .about .charities .description {
  padding: 0 48px;
}

@media screen and (max-width: 600px) {
  .about .technologies .description, .about .books .description, .about .awards .description, .about .publications .description, .about .charities .description {
      padding: 0 24px 24px;
  }
}

.about .technologies .list, .about .books .list, .about .awards .list, .about .publications .list, .about .charities .list {
  padding: 0;
}

.about .technologies .list li, .about .books .list li, .about .awards .list li, .about .publications .list li, .about .charities .list li {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 20%;
  display: block;
  float: left;
}

@media screen and (max-width: 600px) {
  .about .technologies .list li, .about .books .list li, .about .awards .list li, .about .publications .list li, .about .charities .list li {
      width: 50%;
  }
}

.about .technologies .list li .svg, .about .books .list li .svg, .about .awards .list li .svg, .about .publications .list li .svg, .about .charities .list li .svg {
  content: '';
  width: 100%;
  height: 0;
  padding: 70% 0 0 0;
  display: block;
}

.about .technologies .list li .svg svg, .about .books .list li .svg svg, .about .awards .list li .svg svg, .about .publications .list li .svg svg, .about .charities .list li .svg svg {
  position: absolute;
  top: 33%;
  left: 20%;
  width: 60%;
  height: 34%;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.about .technologies .list li .svg:hover svg, .about .books .list li .svg:hover svg, .about .awards .list li .svg:hover svg, .about .publications .list li .svg:hover svg, .about .charities .list li .svg:hover svg {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
}

.about .biography {
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  .about .biography .description {
      margin-bottom: 24px;
  }

  .about .biography ul {
      font-size: 14px;
      font-size: 0.875rem;
  }
}

.about .biography ul li {
  margin: 0 0 0 24px;
  list-style: none;
}

.about .charities {
  border-bottom: none;
}

.about .publications .list li {
  width: 20%;
}

@media screen and (max-width: 600px) {
  .about .publications .list li {
      width: 50%;
  }
}

.about .awards .list li {
  width: 25%;
}

@media screen and (max-width: 600px) {
  .about .awards .list li {
      width: 50%;
  }
}

.about .awards .list li .svg svg {
  position: absolute;
  top: 33%;
  left: 25%;
  width: 50%;
  height: 34%;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.about .awards .list li .svg:hover svg {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
}

.about .technologies .description {
  padding: 0 0px 24px;
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .about .technologies .description {
      padding: 0 24px 24px 24px;
  }
}

.about .technologies ul {
  // padding: 0 0 48px 48px;
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
  list-style-position: inside;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 160%;
}

@media screen and (max-width: 800px) {
  .about .technologies ul {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
  }
}

@media screen and (max-width: 600px) {
  .about .technologies ul {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      padding: 0 24px 48px 24px;
  }
}

.about .technologies ul li {
  padding: 0;
  margin: 0 0 24px;
  break-inside: avoid;
  list-style: none;
}

.about .technologies ul ul {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.about .technologies ul ul li {
  margin: 0;
  padding: 0 0 0 15px;
}

.about .technologies ul ul li:before {
  content: '';
  background: #282828;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 0;
}

.about .technologies ul ul li[data-rating="2"]:before {
  background: #008000;
}

.about .technologies ul ul li[data-rating="3"]:before {
  background: #00f;
}

.about .technologies ul ul li[data-rating="4"]:before {
  background: #ffa500;
}

.about .technologies ul ul li[data-rating="5"]:before {
  background: #f00;
}

.about .technologies .competency {
  max-width: 100% !important;
}

.about .technologies .competency span {
  margin: 0 10px 0 0;
  padding: 0 0 0 20px;
  font-size: 12px;
  font-size: 0.75rem;
}

.about .technologies .competency span:nth-of-type(1) {
  padding: 0;
}

.about .technologies .competency span:not(:nth-of-type(1)):before {
  content: '';
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #efefef;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  left: 2px;
}

.about .technologies .competency span.recreational:before {
  background: #008000;
}

.about .technologies .competency span.limited:before {
  background: #00f;
}

.about .technologies .competency span.advanced:before {
  background: #ffa500;
}

.about .technologies .competency span.expert:before {
  background: #f00;
}


.homepost {
  // position: relative;
  display: inline;
}